import { useState } from "react";

import { sendAccessCodeService } from "./services";
const ForgotCodeModal = ({ 
    onClose, 
    modalError, 
    isLoading ,
    setModalError,
    setIsLoading,
    setIsShowForgotCodeModal
}) => {

    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleForgotCodeSubmit = async (email) => {
        try {
            setModalError('')
            setIsLoading(true)
            const serviceProps = { email }
            sendAccessCodeService(serviceProps)
            setIsShowForgotCodeModal(false);
            setModalError('')
        } catch (error) {
            setModalError('Access code not found.')
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="modal-overlay" style={{backgroundColor:'transparent'}}>
            <div className="modal">
                <h3>Retrieve Access Code</h3>
                <p>Your access code will be emailed to you, if one exists.</p>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleForgotCodeSubmit(email);
                }}>
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Enter your email"
                        autoFocus
                        required
                    />
                    <div className={`modal-error-message ${modalError ? 'active' : ''}`}>
                            {modalError || ' '}
                    </div>
                    {isLoading ?
                        <div className="loading-container">
                            <p>Retrieving...</p>
                            <span className="material-symbols-outlined symbol-running" style={{color:'#00BAE2'}}>autorenew</span>
                        </div>
                    :
                        <div className="modal-actions">
                            <button type="submit" disabled={isLoading}>Submit</button>
                            <button type="button" onClick={onClose} disabled={isLoading}>Cancel</button>
                        </div>
                    }
                    
                </form>
            </div>
        </div>
    );
};

export default ForgotCodeModal
