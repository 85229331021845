import logo from './logo.svg';
import { useState } from 'react';
import getInitials from './helpers/getInitials';
import { getUsernameService } from './services';

const SplashScreen = ({
        setIsShowForgotCodeModal, 
        handleCheckboxChange, 
        isKeepLoggedIn,
        setUser,
        setInitials,
        setAllUsers,
        accessCode,
        setAccessCode
    }) => {

    const [splashScreenError, setSplashScreenError] = useState('');

    const handleAccessCodeChange = (e) => {
        setAccessCode(e.target.value);
        setSplashScreenError("")
    };
        
    const handleAccessCodeSubmit = async (e) => {
        try {
            const serviceProps = { accessCode }
            const response = await getUsernameService(serviceProps)
            const data = response.data.name;
            setUser(data)
            setInitials(getInitials(data))
            setAllUsers([data])
            sessionStorage.setItem('username', JSON.stringify(data));
            sessionStorage.setItem('allUsers', JSON.stringify([data]));
            isKeepLoggedIn && localStorage.setItem('username', JSON.stringify(data));
            isKeepLoggedIn && localStorage.setItem('allUsers', JSON.stringify([data]));
            return data;
        } catch (err) {
            setSplashScreenError("Invalid code")
        }
    }
        
    return (
        <div className="splash-screen">
            <div className="splash-screen-elements">
                <div className='logo'>
                    <img src={logo} alt="ACER Logo" style={{ height: '35px' }} />
                    <h3>ConQuest Monitor</h3>
                </div>
                <div style={{ display: 'flex'}}>
                    <input className='access-code-input' style={{ marginRight: '10px' }} type="text" id="username" name="username" placeholder='Access Code' value={accessCode} onChange={handleAccessCodeChange} autoFocus required />
                    <button onClick={handleAccessCodeSubmit} className='submit-button' style={{ cursor: 'pointer' }} type="submit">Submit</button>
                </div>
                <div className={`splash-error-message ${splashScreenError ? 'active' : ''}`}>
                    {splashScreenError || ' '}
                </div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
                    <div className="checkbox-container">
                        <input className='keep-logged-in-checkbox' type="checkbox" id="keepLoggedIn" name="keepLoggedIn" checked={isKeepLoggedIn} onChange={handleCheckboxChange} />
                        <label className='checkbox-label' htmlFor="keepLoggedIn">Keep me logged in</label>
                    </div>
                    <div className="forgot-link-container">
                        <button className='forgot-access-code-link' onClick={_ => setIsShowForgotCodeModal(true)}>Forgot access code</button>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default SplashScreen