function getInitials(username) {
    if (username.length < 5 || !username.toLowerCase().startsWith('acer')) {
        return username[0].toUpperCase();
    }

    const lastNameInitial = username[4].toUpperCase();
    const firstNameInitial = username[username.length - 1].toUpperCase();

    return firstNameInitial + lastNameInitial;
}

export default getInitials