export const API_KEY = 'bSTQ89KvrXok2uXkQR1W6Rqvmr73uqZSLBgP1n-S0UE'

export const COLOUR_MAP_LIGHT = {
    'Running': '#00BAE2',
    'Completed': '#78D231',
    'Stalled': '#CF000F',
    'Interrupted': '#DBA602'
}

export const COLOUR_MAP_DARK = {
    'Running': 'hsl(191, 45%, 45%)',
    'Completed': 'hsl(94, 45%, 45%)',
    'Stalled': 'hsl(356, 45%, 50%)',
    'Interrupted': 'hsl(45, 45%, 45%)'
}

export const SYMBOL_MAP = {
    'Running': 'autorenew',
    'Completed': 'done',
    'Stalled': 'error',
    'Interrupted': 'warning'
}

export const LOCAL_HOST_URL = 'http://127.0.0.1:5000'

export const PRODUCTION_URL = 'https://conquest-monitor.onrender.com'