const getNameFromUsername = (username) => {
    if (username.length < 6 || !username.toLowerCase().startsWith('acer')) {
        return username;
    }

    const initial = username[username.length - 1].toUpperCase();
    const lastName = username.substring(4, username.length - 1);

    return `${initial}. ${lastName.charAt(0).toUpperCase() + lastName.slice(1)}`;
}

export default getNameFromUsername