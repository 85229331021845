import { 
    COLOUR_MAP_LIGHT, 
    COLOUR_MAP_DARK, 
    SYMBOL_MAP
} from './context';

import { Line } from 'react-chartjs-2';

import { 
    deleteMonitoringDataService, 
    deleteDevianceDataService 
} from './services';

const ConQuestRunInstance = ({
    machineData,
    setLatestData,
    toggleBlock,
    setShowFullData,
    username,
    openBlocks,
    systemChartsData,
    showFullData,
    isDarkMode
}) => {

    const handleDeleteLog = async (uuid) => {
        try {
            const serviceProps = { uuid  }
            await deleteMonitoringDataService(serviceProps)
            await deleteDevianceDataService(serviceProps)

            //TODO replace this with a call to fetch
            setLatestData(prevData => {
                const newData = { ...prevData };
                for (const user in newData) {
                    newData[user] = newData[user].filter(machine => machine.uuid !== uuid);
                }
                return newData;
            })

        } catch (error) {
            console.error('Error deleting log data:', error);
        } finally {

        }
    };


    const graphAxisColour = isDarkMode ? 'rgb(180, 180, 180)' : 'rgb(80, 80, 80)'
    const graphGridColour = isDarkMode ? 'rgba(180, 180, 180, 0.25)' : 'rgba(80, 80, 80, 0.25)'
    const COLOUR_MAP = isDarkMode ? COLOUR_MAP_DARK : COLOUR_MAP_LIGHT
    
    return (
        <div key={machineData.uuid} className="machine-log" style={{borderLeft: `4px solid ${COLOUR_MAP[machineData.status]}`}}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div className="detail-row">
                <h3 className="detail-label">System:</h3>
                <strong>{machineData.system}</strong>
            </div>
            <div>
                <button disabled={['Running', 'Stalled'].includes(machineData.status)} onClick={() => handleDeleteLog(machineData.uuid)}
                    style={{
                        marginLeft:'0.2rem',padding:0,
                        opacity: ['Running', 'Stalled'].includes(machineData.status) ? '0.3' : '1',
                        cursor: ['Running', 'Stalled'].includes(machineData.status) ? 'default' : 'pointer'
                    }}>
                    <span className="material-symbols-outlined">delete</span>
                </button>
            </div>
        </div>
        <div className="detail-row">
            <h3 className="detail-label">Label:</h3>
            <strong>{machineData.label}</strong>
        </div>
        <div className="detail-row">
            <h3 className="detail-label">Status:</h3>
            <strong>{machineData.status}</strong>
            <span className={`material-symbols-outlined symbol-${machineData.status.toLowerCase()}`} style={{color:COLOUR_MAP[machineData.status]}}>{SYMBOL_MAP[machineData.status]}</span>
        </div>
        <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <button onClick={() => toggleBlock(username,machineData.uuid)} style={{ alignSelf: 'start', padding:'0.5rem', margin:'-0.5rem' }}>
                {openBlocks[username]?.[machineData.uuid] 
                    ? <span className="material-symbols-outlined">expand_less</span> 
                    : <span className="material-symbols-outlined">expand_more</span>}
            </button> 
            <div style={{marginLeft:'5rem', fontSize:'small', marginBottom:'1em', marginTop:'-0.3em'}}>
                <span>{` as of ${new Date(machineData.last_updated).toLocaleString()}`}</span>
            </div>
        </div>
        {openBlocks[username]?.[machineData.uuid] && systemChartsData[username]?.[machineData.uuid]?.labels && ( 
            <div>
                <div className="detail-row">
                    <h4 className="detail-label">Started at:</h4>
                    <span>{new Date(machineData.created_at).toLocaleString()}</span>
                </div>
                <div className="detail-row">
                    <h4 className="detail-label">Iteration:</h4>
                    <span>{machineData.iteration}</span>
                </div>
                <div className="detail-row">
                    <h4 className="detail-label">Deviance:</h4>
                    <span>{machineData.deviance}</span>
                </div>
                <div style={{display:'flex', }}>
                    <h4>Live Deviance vs Iteration Chart</h4>
                    <button style={{marginLeft:'1rem'}} onClick={() => setShowFullData(prev => !prev)}>
                        {showFullData ? 'Show Last 20' : 'Show Full Data'}
                    </button>
                </div>
                <div style={{maxWidth:'720px'}}>
                    <Line
                        data={systemChartsData[username][machineData.uuid]}
                        options={{
                            responsive: true,
                            scales: {
                                y: {
                                    beginAtZero: false,
                                    grid: { color: graphGridColour },
                                    ticks: {color: graphAxisColour , font:{size:10}, padding:0},           
                                    title: {
                                        display: true,
                                        text: 'Deviance', 
                                        color: graphAxisColour, 
                                    }
                                },
                                x: {
                                    grid: { color: graphGridColour },
                                    ticks: { color: graphAxisColour, stepSize: 1 },
                                    title: {
                                        display: true,
                                        text: 'Iteration', 
                                        color: graphAxisColour, 
                                    }
                                }
                            },
                            plugins: {
                                legend: { display: false }
                            }
                        }}
                    />
                </div>
                <h4>{`Console output (last ${machineData.output.length} lines)`}</h4>
                <div className="console-output">{machineData.output.join('\n')}</div>
            </div>
        )}
    </div>
    )
}

export default ConQuestRunInstance