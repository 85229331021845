import axios from "axios";

import { 
    LOCAL_HOST_URL, 
    PRODUCTION_URL
} from "./context";

import { API_KEY } from "./context";

const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
axios.defaults.baseURL = isLocalhost ? LOCAL_HOST_URL : PRODUCTION_URL;

axios.defaults.headers.common['Authorization'] = API_KEY;

export async function deleteMonitoringDataService(serviceProps){
    const uuid = serviceProps.uuid;
    return await axios.delete(`/deletemonitoringdata?uuid=${encodeURIComponent(uuid)}`);
}

export async function deleteDevianceDataService(serviceProps){
    const uuid = serviceProps.uuid;
    return await axios.delete(`/deletedeviancedata?uuid=${encodeURIComponent(uuid)}`);
}

export async function getUsernameService(serviceProps){
    const accessCode = serviceProps.accessCode
    return await axios.get(`/getusername?code=${encodeURIComponent(accessCode)}`)
}

export async function sendAccessCodeService(serviceProps){
    return await axios.post(`/send-access-code`, serviceProps)
}

export async function addExternalAccessCodeService(serviceProps){
    const externalAccessCode = serviceProps.externalAccessCode
    return await axios.get(`/getusername?code=${encodeURIComponent(externalAccessCode)}`)
}
