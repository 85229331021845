import { useState } from "react";
import { addExternalAccessCodeService } from "./services";

const AddRunsModal = ({ 
    setModalError, 
    modalError, 
    closeExternalRunModal, 
    setAllUsers,
    isKeepLoggedIn,
    allUsers
}) => {

    const [externalAccessCode, setExternalAccessCode] = useState('');

    const handleExternalAccessCodeSubmit = async (e) => {
        try {
            const serviceProps = { externalAccessCode }
            const response = await addExternalAccessCodeService(serviceProps)
            const data = response.data.name;

            if (allUsers.includes(data)) {
                throw new Error('User already added')
            }

            const newAllUsers = [...allUsers, data]
            setAllUsers(newAllUsers)
            sessionStorage.setItem('allUsers', JSON.stringify(newAllUsers))
            isKeepLoggedIn && localStorage.setItem('allUsers', JSON.stringify(data))
            closeExternalRunModal()

        } catch (err) {
            if (err.response?.data?.message) {
                setModalError(err.response.data.message);
            } else if (err.message) {
                setModalError(err.message);
            } else {
                setModalError("An error occurred while fetching the username.");
            }
        }
    };

    return(
        <div className="modal-overlay">
            <div className="modal">
                <h3>Add External Runs</h3>
                <div onClick={handleExternalAccessCodeSubmit}>
                    <input
                        style={{width: '95%'}}
                        type="text"
                        value={externalAccessCode}
                        onChange={(e) => {
                            setExternalAccessCode(e.target.value)
                            setModalError("")
                    }}
                    placeholder="Enter another access code"
                    autoFocus
                    required
                    />
                    <div className={`modal-error-message ${modalError ? 'active' : ''}`}>
                        {modalError || ' '}
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <button type="submit">Submit</button>
                        <button onClick={closeExternalRunModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddRunsModal