import { useState } from "react";

const UserMenu = ({
    user, 
    openExternalRunModal, 
    toggleDarkMode,
    dropdownRef,
    handleLogOut,
    initials,
    isDarkMode,
    isDropdownVisible,
    setIsDropdownVisible
}) => {

    return (
      <div className="user-menu">
        <div className="initials" onClick={_ => setIsDropdownVisible(prevState => !prevState)}>
          {initials}
        </div>
        {isDropdownVisible && (
          <div className="dropdown" ref={dropdownRef}>
            <div className="dropdown-item">
                <span class="material-symbols-outlined dropdown-symbol">account_circle</span>
                <strong style={{padding:'0.5em 0'}}>{user}</strong>
            </div>
            <div className="dropdown-item clickable" onClick={openExternalRunModal}>
                <span class="material-symbols-outlined dropdown-symbol">library_add</span>
                <span className='dropdown-item-text'>Add external runs</span> 
            </div>
            {isDarkMode ? 
                <div className="dropdown-item clickable" onClick={toggleDarkMode}>
                    <span class="material-symbols-outlined dropdown-symbol">light_mode</span>
                    <span className='dropdown-item-text'>Light mode</span> 
                </div>  
                : 
                <div className="dropdown-item clickable" onClick={toggleDarkMode}>
                    <span class="material-symbols-outlined dropdown-symbol">dark_mode</span>
                    <span className='dropdown-item-text'>Dark mode</span> 
                </div>  
            }
            <div className="dropdown-item clickable" onClick={handleLogOut}>
                <span class="material-symbols-outlined dropdown-symbol">logout</span>
                <span className='dropdown-item-text'>Log out</span> 
            </div>
          </div>
        )}
      </div>
    );
};

export default UserMenu