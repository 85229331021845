import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import './App.css'; 
import logo from './logo.svg';
import '@fontsource/roboto';
import '@fontsource/poppins';

import ForgotCodeModal from './ForgotCodeModal'
import UserMenu from './UserMenu'
import SplashScreen from './SplashScreen'
import AddRunsModal from './AddRunsModal';

import getNameFromUsername from './helpers/getNameFromUsername';

import { 
    COLOUR_MAP_LIGHT, 
    COLOUR_MAP_DARK, 
    API_KEY,
    LOCAL_HOST_URL,
    PRODUCTION_URL
} from './context';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ConQuestRunInstance from './ConQuestRunInstance';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
const SERVER_URL = isLocalhost ? LOCAL_HOST_URL : PRODUCTION_URL;

// TODO: Refactor code!!

function App() {
    const [latestData, setLatestData] = useState({});
    const [error, setError] = useState(null);
    const [accessCode, setAccessCode] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const [lastRefreshed,setLastRefreshed] = useState()
    const [systemChartsData, setSystemChartsData] = useState({});
    const [isKeepLoggedIn, setIsKeepLoggedIn] = useState(false);
    const [isAddRunsModalVisible, setIsAddRunsModalVisible] = useState(false);
    const [modalError, setModalError] = useState('');
    const dropdownRef = useRef(null);
    const [isShowForgotCodeModal, setIsShowForgotCodeModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [showFullData, setShowFullData] = useState(true);
    const [initials, setInitials] = useState('')
    const [openBlocks, setOpenBlocks] = useState(() => {
        const savedOpenBlocks = sessionStorage.getItem('openBlocks') || localStorage.getItem('openBlocks');
        return savedOpenBlocks ? JSON.parse(savedOpenBlocks) : {};
    });
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const storedMode = sessionStorage.getItem('isDarkMode') || localStorage.getItem('isDarkMode');
        return storedMode ? JSON.parse(storedMode) : false;
    });
    const [user,setUser] = useState(() => {
        const savedUsername = sessionStorage.getItem('username') || localStorage.getItem('username');
        return savedUsername ? JSON.parse(savedUsername) : "";
    });
    const [allUsers,setAllUsers] = useState(() => {
        const savedUsernames = sessionStorage.getItem('allUsers') || localStorage.getItem('allUsers');
        return savedUsernames ? JSON.parse(savedUsernames) : [];
    });

    

    const fetchLatestData = useCallback(async (username) => {
        try {
            const response = await axios.get(`${SERVER_URL}/getlatest?user=${encodeURIComponent(username)}`,{
                headers: {
                    'Authorization':  API_KEY
                }
            });

            response.data.forEach(machine => {
                if ((machine.status === 'Running' || machine.status === 'Stalled') && new Date() - new Date(machine.last_updated) > 5 * 60 * 1000) {
                    machine.status = 'Interrupted'
                }
            })
            
            const statusPriority = {
                'Running': 1,
                'Stalled': 2,
                'Interrupted': 3,
                'Completed': 4
            };

            const compareStatus = (a, b) => {
                return statusPriority[a.status] - statusPriority[b.status];
            };

            const compareCreatedAt = (a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return dateB - dateA;  
            };

            const sortedData = response.data.sort((a, b) => {
                const statusComparison = compareStatus(a, b);
                if (statusComparison !== 0) {
                    return statusComparison;
                }
                return compareCreatedAt(a, b);
            });

            setLatestData(prevData => ({
                ...prevData,
                [username]: sortedData
            }))

            response.data.forEach(async (machineData) => {
                const fetchedDevianceData = await fetchDevianceData(machineData.uuid);
                updateChartData(username ,machineData.uuid, machineData.status, fetchedDevianceData, isDarkMode);
            });

        } catch (err) {
            setError(err.message);
        } finally {
            const now = new Date();
            const formattedTime = now.toLocaleString({
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            });
            setLastRefreshed(formattedTime)
        }
    }, [isDarkMode, showFullData]); 


    const fetchDevianceData = useCallback(async (uuid) => {
        try {
            const response = await axios.get(`${SERVER_URL}/getdeviances?uuid=${encodeURIComponent(uuid)}`, {
                headers: {
                    'Authorization': API_KEY
                }
            });
            const fetchedDevianceData = response.data.data;
            return fetchedDevianceData;
        } catch (err) {
            console.error('Error fetching deviance data:', err);
            return [];
        }
    }, []);


    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownVisible(false);
        }
    };

    const handleLogOut = () => {
        sessionStorage.clear()
        localStorage.clear()
        setUser("")
        setInitials("")
        setIsKeepLoggedIn(false)
        setAccessCode("")
        setIsDropdownVisible(false)
        setIsDarkMode(false)
        setOpenBlocks({})
        setLatestData({})
        setAllUsers([])
    }


    const updateChartData = (username, uuid, status, devianceData, mode) => {
        const colorMap = mode ? COLOUR_MAP_DARK : COLOUR_MAP_LIGHT;
        const slicedData = showFullData ? devianceData : devianceData.slice(-20);

        setSystemChartsData(prevData => ({
            ...prevData,
            [username]: {
                ...prevData[username] ?? {},
                [uuid]: {
                    labels: slicedData.map(item => String(item.iteration)), 
                    datasets: [{
                        label: `Deviance`,
                        data: slicedData.map(item => item.deviance), 
                        borderColor: colorMap[status] ,
                    }]
                } 
            }
        }));
    };


    const toggleBlock = (username,uuid) => {
        setOpenBlocks(prevOpenBlocks => {
            const newOpenBlocks = {
                ...prevOpenBlocks,
                [username]: {
                    ...prevOpenBlocks[username] ?? {},
                    [uuid]: !prevOpenBlocks[username]?.[uuid]
                }
            }

            sessionStorage.setItem('openBlocks', JSON.stringify(newOpenBlocks));
            isKeepLoggedIn && localStorage.setItem('openBlocks', JSON.stringify(newOpenBlocks));
            return newOpenBlocks;
        });
    };

    const toggleDarkMode = () => {
        setIsDarkMode(prevMode => {
            const newMode = !prevMode;
            sessionStorage.setItem('isDarkMode', JSON.stringify(newMode));
            isKeepLoggedIn && localStorage.setItem('isDarkMode', JSON.stringify(newMode));

            const newColourMap = newMode ? COLOUR_MAP_DARK : COLOUR_MAP_LIGHT;
            const updatedSystemChartsData = {};
            
            for (const username in [allUsers]) {
                for (const uuid in systemChartsData[username]) {
                    const status = latestData[username].find(data => data.uuid === uuid)?.status || 'Running'; 
                    updatedSystemChartsData[username][uuid] = {
                        ...systemChartsData[username][uuid],
                        datasets: systemChartsData[username][uuid].datasets.map(dataset => ({
                            ...dataset,
                            borderColor: newColourMap[status],
                        }))
                    };
                }
            }
            

            setSystemChartsData(updatedSystemChartsData);
            return newMode;
        });
    };


    const openExternalRunModal = () => {
        setIsAddRunsModalVisible(true);
        setIsDropdownVisible(false)
    };

    const closeExternalRunModal = () => {
        setIsAddRunsModalVisible(false);
        setModalError('');
    };



    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        if (allUsers.length > 0) {
            allUsers.forEach(username => fetchLatestData(username))
            const intervalId = setInterval(() => {allUsers.forEach(username => fetchLatestData(username))}, 15000); 
            return () => {
                clearInterval(intervalId)
                document.removeEventListener("mousedown", handleClickOutside)
            } 
        } else {
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
              };
        }
    }, [user, allUsers, fetchLatestData]);

    return (
        <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
            {!user ?
            <>
                {isShowForgotCodeModal && 
                    <ForgotCodeModal
                        onClose={() => {
                            setIsShowForgotCodeModal(false)
                            setModalError('')}}
                        modalError={modalError}
                        isLoading={isLoading}
                        setModalError={setModalError}
                        setIsLoading={setIsLoading}
                        setIsShowForgotCodeModal={setIsShowForgotCodeModal}
                    />    
                }
                <SplashScreen
                    setIsShowForgotCodeModal={setIsShowForgotCodeModal}
                    handleCheckboxChange={e => setIsKeepLoggedIn(e.target.checked)}
                    isKeepLoggedIn={isKeepLoggedIn}
                    setUser={setUser}
                    setInitials={setInitials}
                    setAllUsers={setAllUsers}
                    accessCode={accessCode}
                    setAccessCode={setAccessCode}
                />
            </>
            : <>
                <header className="App-header">
                    <div className="logo">
                        <img src={logo} alt="ACER Logo" style={{ height: '35px' }} />
                        <h3>ConQuest Monitor</h3>
                    </div>
                    <UserMenu 
                        user={user}
                        openExternalRunModal={openExternalRunModal} 
                        toggleDarkMode={toggleDarkMode}
                        dropdownRef={dropdownRef}
                        handleLogOut={handleLogOut}
                        initials={initials}
                        isDarkMode={isDarkMode}
                        isDropdownVisible={isDropdownVisible}
                        setIsDropdownVisible={setIsDropdownVisible}
                    />
                </header>
                <main className="content-container">
                    {isAddRunsModalVisible && 
                        <AddRunsModal
                            allUsers={allUsers}
                            setAllUsers={setAllUsers}
                            setModalError={setModalError}
                            modalError={modalError}
                            isKeepLoggedIn={isKeepLoggedIn}
                            closeExternalRunModal={closeExternalRunModal} 
                        />
                    }
                    {latestData[user] && latestData[user].length > 0 ? (
                        <div>
                            <p style={{marginBottom:'0.5rem'}}>Last updated: {lastRefreshed}</p>
                            {allUsers.length > 0 && allUsers.map(username => 
                                <>
                                    <h4 className='username-header'>Runs by {getNameFromUsername(username)}</h4>
                                    {latestData[username] && latestData[username].map((machineData, index) => (
                                        <ConQuestRunInstance 
                                            machineData={machineData}
                                            setLatestData={setLatestData}
                                            toggleBlock={toggleBlock}
                                            setShowFullData={setShowFullData}
                                            username={username}
                                            openBlocks={openBlocks}
                                            systemChartsData={systemChartsData}
                                            showFullData={showFullData}
                                            isDarkMode={isDarkMode}
                                        />
                                    ))}
                                </>     
                            )}
                        </div>
                    ) : (
                        <p>There are no ConQuest runs to view yet...</p>
                    )}
                </main>
            </>
            } 
        </div>
    );
}

export default App;